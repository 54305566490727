body {
  font-family: 'Raleway', sans-serif;
  font-weight: 600px;
}

.dashboard-wrapper {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}

.dashboard-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 20px 0;
}

.dashboard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
}

.dashboard-header h1 {
  align-items: left;
  font-size: 24px;
}

.user-namelink {
  display: flex;
  align-items: center;
}

.signout-btn {
  margin-left: auto;
  padding: 10px;
  border: none;
  background-color: #f8f9fa;
  cursor: pointer;
  transition: background-color 0.3s;
  border-radius: 5px;
  font-family: 'Raleway', sans-serif;
}

.signout-btn:hover {
  background-color: #e9ecef;
}

.user-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
}

.user-avatar-dashboard {
  width: 110px !important;
  height: 110px !important;
  z-index: 1;
  margin-top: 4%;
  border-radius: 50%;
  object-fit: cover;
}

.user-username {
  margin: 10px 0;
  font-weight: 700;
  font-size: 24px;
}

.sales-btn {
  font-family: 'Raleway', sans-serif !important;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  text-align: left;
  margin: 20px 0;
  border: none;
  outline: none;
  padding: 10px;
  border-top: 2px solid #f3f3f3;
  border-bottom: 2px solid #f3f3f3;
  font-size: 18px;
  color: #333;
  background-color: #fff;
  cursor: pointer;
  transition: background-color 0.3s;
  position: relative;
  border-radius: 3px;
}

.sales-btn:after {
  content: '';
  position: absolute;
  right: 10px;
  border: solid #d2cdcd;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 5px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.sales-btn:hover {
  background-color: #f3f3f3;
}

.dashboard-store-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  padding: 20px;
}

.dashboard-store-item {
  position: relative;
  width: 100%;
  padding-top: 100%;
  background-color: #F3F3F3;
  overflow: hidden;
  border-radius: 0px;
}

.dashboard-store-item-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  object-fit: cover;
}

.dashboard-store-item-tag {
  position: absolute;
  bottom: 10px;
  right: 10px;
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 12px;
  color: #fff;
}

.dashboard-store-item-tag.new {
  background-color: #4caf50;
}

.dashboard-store-item-tag.used {
  background-color: #ff9800;
}

.add-button {
  display: block;
  width: 100%;
  padding: 15px 0;
  margin-top: 30px;
  font-size: 16px;
  text-align: center;
  color: #fff;
  background-color: #000;
  border: none;
  border-radius: 7px;
  cursor: pointer;
  text-decoration: none;
  font-weight: bold;
}

.add-button-image {
  display: inline-block; /* Changed to inline-block */
  width: 100%;
  padding: 15px 0;
  margin-bottom: 30px;
  font-size: 16px;
  text-align: center;
  color: #fff;
  background-color: #000;
  border: none;
  border-radius: 7px;
  cursor: pointer;
  text-decoration: none;
  font-weight: bold;
  position: relative; /* Added */
  overflow: hidden; /* Added */
  transition: background-color 0.3s; /* Added for smooth transition */
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed; 
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto; 
  background-color: rgba(0,0,0,0.4);
  overflow-x: hidden;
}

.modal-content {
  background-color: #fefefe;
  margin: auto;
  border: 1px solid #888;
  width: 100%;
  height: 100%;
  padding: 7%;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
}

.modal-close-btn {
  top: 10px;
  left: 10px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 40px;
}

.modal-content input,
.modal-content textarea,
.modal-content select {
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  margin-bottom: 15px;
  border: 0px solid #ccc;
  border-radius: 5px;
  background-color: #f3f3f3;
  box-sizing: border-box; /* Add this property */
}

.modal-content button {
  padding: 10px 20px;
  background-color: #000;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.remove-image-btn {
  position: absolute;  /* This positions the button relative to the nearest positioned ancestor, i.e., .dashboard-store-item */
  top: 10px;  /* Adjust as necessary to move the button up/down */
  right: 10px;  /* Adjust as necessary to move the button left/right */
  background-color: #f3f3f3;  /* Button's background color */
  border: none;  /* Removes button's default border */
  border-radius: 50%; /* Rounds the button */
  width: 30px; /* Sets the button's width - adjust as necessary */
  height: 30px; /* Sets the button's height - adjust as necessary */
  display: flex; /* Aligns the svg in the center */
  justify-content: center; /* Aligns the svg horizontally in the center */
  align-items: center; /* Aligns the svg vertically in the center */
}

.remove-image-btn svg {
  fill: #000; /* Sets the svg color to black - adjust as necessary */
}

.modal-content-payment {
  background-color: #fefefe;
  margin: auto;
  width: 80%;
  max-width: 500px;
  padding-left: 7%;
  padding-right: 7%;
  padding-bottom: 5%;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;  /* Add this to make .modal-close-btn position relative to .modal-content */
}

.dashboard-store-item-trash {
  position: absolute;  /* This positions the button relative to the nearest positioned ancestor, i.e., .dashboard-store-item */
  top: 10px;  /* Adjust as necessary to move the button up/down */
  right: 10px;  /* Adjust as necessary to move the button left/right */
  background-color: #f3f3f3;  /* Button's background color */
  border: none;  /* Removes button's default border */
  border-radius: 50%; /* Rounds the button */
  width: 30px; /* Sets the button's width - adjust as necessary */
  height: 30px; /* Sets the button's height - adjust as necessary */
  display: flex; /* Aligns the svg in the center */
  justify-content: center; /* Aligns the svg horizontally in the center */
  align-items: center; /* Aligns the svg vertically in the center */
}

.dashboard-store-item-trash svg {
  fill: #000; /* Sets the svg color to black - adjust as necessary */
}

.copy-link-btn {
  border: none;
  padding: 10px;
  padding-left: 10px;
  padding-right: 10px;
  margin-right: 10px;
  cursor: pointer;
  top: 10px;  /* Adjust as necessary to move the button up/down */
  right: 10px;  /* Adjust as necessary to move the button left/right */
  border: none;  /* Removes button's default border */
  border-radius: 50%; /* Rounds the button */
  display: flex; /* Aligns the svg in the center */
  justify-content: center; /* Aligns the svg horizontally in the center */
  align-items: center; /* Aligns the svg vertically in the center */
  margin-left: 2%;
  background-color: white;
}

.copy-link-btn svg {
  fill: white;
}

.signout-container {
  display: flex;
  align-items: center;
}

.icon-btn {
  background-color: #f8f9fa;
  border: none;
  padding: 10px;
  padding-left: 10px;
  padding-right: 10px;
  margin-right: 10px;
  cursor: pointer;
  top: 10px;  /* Adjust as necessary to move the button up/down */
  right: 10px;  /* Adjust as necessary to move the button left/right */
  background-color: #f3f3f3;  /* Button's background color */
  border: none;  /* Removes button's default border */
  border-radius: 50%; /* Rounds the button */
  display: flex; /* Aligns the svg in the center */
  justify-content: center; /* Aligns the svg horizontally in the center */
  align-items: center; /* Aligns the svg vertically in the center */
}

.icon-btn svg {
  fill: white;
}


.alert-container {
  padding: .75rem 1.25rem;
  margin-bottom: 1rem;
  margin-top: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  color: #e2a461; /* Dark orange for the text */
  background-color: rgb(250, 240, 207); /* Light orange for the background */
  border-color: rgb(250, 240, 207); /* Light orange for the background */
  position: relative;
  text-align: center;
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
}

.alert-container a {
  color: #8a6d3b; /* Dark orange for the text */
  font-weight: bold;
  text-decoration: true;
}

.alert-container p {
  margin-bottom: 0;
  color: #8a6d3b; /* Dark orange for the text */
}

.overlay {
  position: relative;
}

.overlay:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(128, 128, 128, 0.6); /* Grey with 60% opacity */
  z-index: 1;
}
.item-image-container img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 15px;
}

.item-image-container input[type="text"] {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 90%;
  padding: 10px;
  border: none;
  border-radius: 7px;
  background-color: #f3f3f3;
  font-size: 16px;
}

.item-condition-status {
  position: absolute;
  top: 10px;
  left: 10px;
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 12px;
  color: #fff;
  z-index: 1;
}

.item-condition {
  position: absolute;
  top: 40px; /* Adjust this value */
  left: 10px;
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 12px;
  color: #fff;
  z-index: 1;
}

.condition-container {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 1;
  display: flex;
  flex-direction: column;  /* This will stack the child items vertically */
}

.item-condition-status, .item-condition {
  white-space: nowrap;  /* Prevents wrapping of text */
}

.item-condition-status.sold {
  background-color: #4caf50;
}

.item-condition.used {
  background-color: #ff9800;
}

.item-condition.new {
  background-color: #0088ff;
}

@media (max-width: 800px) {
  .condition-container {
    position: absolute;
    top: 50px;
    left: 5px;
    z-index: 1;
    display: flex;
    flex-direction: column;  /* This will stack the child items vertically */
  }
  .item-condition-status {
    position: absolute;
    top: 30%;
    left: 1px;
    padding: 3px 5px;
    border-radius: 20px;
    font-size: 7px;
    color: #fff;
    z-index: 1;
  }
  .item-condition {
    visibility: hidden;
  }
}