.item-page {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}

.back-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 7px;
  background-color: #f3f3f3;
  cursor: pointer;
  border: none;
  margin-bottom: 10px;
}

.back-button img {
  width: 50%;
  height: auto;
}

.item-image-container {
  position: relative;
  margin: 0 auto;
  width: 60%;
  padding-top: 60%;
}

.item-image-container img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 15px;
}

.item-image-container input[type="text"] {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 90%;
  padding: 10px;
  border: none;
  border-radius: 7px;
  background-color: #f3f3f3;
  font-size: 16px;
}

.item-text-container input[type="text"],
.item-text-container input[type="number"],
.item-text-container textarea {
  display: block;
  width: 100%;
  padding-left: 10px;
  box-sizing: border-box;
  padding-top: 10px;
  padding-bottom: 10px;
  margin: 10px 0;
  border: none;
  border-radius: 7px;
  background-color: #f3f3f3;
  font-size: 16px;
}

.item-text-container select {
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  margin: 10px 0;
  border: 0px solid #ccc;
  border-radius: 5px;
  background-color: #f3f3f3;
  box-sizing: border-box; /* Add this property */
}

.item-text-container textarea {
  min-height: 100px;
  resize: vertical;
}

.item-condition-status-edit {
  position: absolute;
  top: 10px;
  left: 10px;
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 12px;
  color: #fff;
  z-index: 1;
}

.item-condition-status-edit {
  white-space: nowrap;  /* Prevents wrapping of text */
}

.item-condition-status-edit.sold {
  background-color: #4caf50;
}

@media (max-width: 800px) {
  .item-condition-edit {
    visibility: hidden;
  }
  .item-condition-status-edit {
    position: absolute;
    top: 1px;
    left: 10px;
    padding: 5px 10px;
    border-radius: 20px;
    font-size: 12px;
    color: #fff;
    z-index: 1;
  }
}

.save-button {
  display: block;
  width: 100%;
  padding: 15px 0;
  margin-top: 30px;
  font-size: 16px;
  text-align: center;
  color: #fff;
  background-color: #000;
  border: none;
  border-radius: 7px;
  cursor: pointer;
  text-decoration: none;
  font-weight: bold;
}

.change-button {
  display: block;
  padding: 10px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  font-size: 16px;
  text-align: center;
  color: #fff;
  background-color: #000;
  border: none;
  border-radius: 7px;
  cursor: pointer;
  text-decoration: none;
  font-weight: bold;
}
