.user-container-client {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
}

.user-avatar-store {
  width: 110px !important;
  height: 110px !important;
  z-index: 1;
  margin-top: 4%;
  border-radius: 50%;
  object-fit: cover;
}

.user-username {
  margin: 10px 0;
  font-weight: 700;
  font-size: 24px;
}