body {
    font-family: 'Raleway', sans-serif;
    font-weight: 700; /* This is for making the text bold */
  }

  .checkout {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .checkout form {
    display: flex;
    flex-direction: column;
  }

  .back-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 7px; /* Make edges rounded with 7px */
    background-color: #f3f3f3; /* Background color */
    cursor: pointer;
    border: none;
  }
  
  .back-button img {
    width: 50%; /* Reduce the size of the arrow */
    height: auto;
  }
  
  .checkout form select {
    width: 100%;
    padding: 10px 15px;
    margin-bottom: 20px;
    border: none;
    border-radius: 7px;
    background-color: #f3f3f3; /* Background color */
    font-family: 'Raleway', sans-serif;
    font-weight: 700; /* This is for making the text bold */
  }
  
  .checkout form select:focus {
    outline: none;
    box-shadow: 0 0 10px rgba(0,0,0,0.25);
  }
  

  .checkout form input {
    padding: 10px;
    margin: 5px 0;
    border-radius: 7px;
    border: none;
    background-color: #f3f3f3;
  }
  
  .order-button {
    padding: 15px 0;
    margin-top: 20px;
    font-size: 16px;
    text-align: center;
    color: #fff;
    background-color: #000;
    border: none;
    border-radius: 7px;
    cursor: pointer;
    text-decoration: none;
    font-weight: bold;
  }

  .checkout form .row {
    display: flex;
    justify-content: space-between;
  }
  
  .checkout form .row input {
    width: 48%;
    margin-right: 2%;
  }
  
  
  .checkout .item-details {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .checkout .item-details .item-image {
    width: 100px;
    height: 100px;
    border-radius: 7px;
    object-fit: cover;
    margin-right: 20px;
  }
.item_description{
  color: grey;
}

.checkout-header{
  display: flex;
  justify-content: left;
  align-items: center;
}

.checkout-title{
  margin-left: 30px;
}

.checkout form select {
  width: 100%;
  padding: 10px;  /* Adjust this value as required */
  margin-bottom: 20px;
  border: none;
  border-radius: 7px;
  background-color: #f3f3f3; /* Background color */
  font-family: 'Raleway', sans-serif;
  font-weight: 700; /* This is for making the text bold */
  height: 39px;  /* Adjust this value as required */
  box-sizing: border-box;
}


.checkout form select:focus {
  outline: none;
  box-shadow: 0 0 10px rgba(0,0,0,0.25);
}

.purchase-transfer {
  width: 100%; /* Make the button take the full width of the parent */
  padding: 15px 0;
  margin-top: 20px;
  font-size: 16px;
  text-align: center;
  color: #fff;
  background-color: #000;
  border: none;
  border-radius: 7px;
  cursor: pointer;
  text-decoration: none;
  font-weight: bold;
}


.checkout-delivery-options label {
  display: flex;
  align-items: center;
}

.checkout-delivery-options input[type="radio"] {
  margin-right: 5px;
}

.checkout-total-cost {
  text-align: left;
}


.rounded-checkbox {
  width: 16px;
  height: 16px;
  background-color: #eee;
  border-radius: 50%;
  vertical-align: middle;
  border: 1px solid #ddd;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
}

.rounded-checkbox:checked {
  background-color: #0a74c4;
}

.checkout-delivery-options {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 9999;
}
