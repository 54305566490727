.payment-success-container {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #f8f9fa;
    color: #212121;
    text-align: center;
    padding: 20px;
    box-sizing: border-box;
}

.checkmark {
    width: 150px;
    height: 150px;
}

.success-title {
    margin: 20px 0;
    color: #3f51b5;
    font-size: 2em;
}

.success-message {
    margin-bottom: 20px;
}
