body {
  font-family: 'Raleway', sans-serif;
  font-weight: 600; /* Note: Removed "px" from font-weight value as font-weight doesn't accept pixel units */
}

.horizontal-scrolling {
  overflow-x: auto;
  max-width: 100%;
  scrollbar-width: thin;
  scrollbar-color: #888 #eee; /* You can adjust the colors as per your theme */
}

.horizontal-scrolling::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
}

.horizontal-scrolling::-webkit-scrollbar-track {
  background-color: #eee;
  border-radius: 4px;
}

.dashboard-header-sales {
  margin-bottom: 20px;
  width: 100%;
}

.back-button-sales {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 7px;
  background-color: #f3f3f3;
  cursor: pointer;
  border: none;
  margin-bottom: 10px;
}

.back-button-sales img {
  width: 50%;
  height: auto;
}

.sales-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 20px;
}

.sales-container h1 {
  margin-bottom: 20px;
}

.sales-container table {
  width: 100%;
  border-collapse: collapse;
  
}

.sales-container th,
.sales-container td {
  border: 1px solid #ddd;
  padding: 12px;
  text-align: left;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.sales-container tr:nth-child(even) {
  background-color: #f9f9f9;
}

.sales-container tr:hover {
  background-color: #eaeaea;
}

.sales-container th {
  background-color: white;
  color: black;
}

.sales-container td img {
  height: 40px;
  width: auto;
  margin-right: 10px;
}

.sales-container a {
  color: #000;
  text-decoration: none;
}

.sales-container a:hover {
  color: #666;
}

.sales-container select {
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #f1f1f1;
  transition: all 0.3s ease;
  cursor: pointer;
}

.sales-container select:hover {
  background-color: #ddd;
}

.how-to-send-link {
  color: #337ab7;
  text-decoration: none;
  font-weight: 600;
}

.how-to-send-link:hover {
  text-decoration: underline;
}

.item-details-sales {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.item-details-sales .item-image-sales {
  width: 100px;
  height: 100px;
  border-radius: 7px;
  object-fit: cover;
  margin-right: 20px;
}

.item_description-sales {
  color: grey;
}

.sales-container th:nth-child(8),
.sales-container td:nth-child(8) {
    width: 300px !important;
}

/* Media Queries for mobile responsiveness */
@media (max-width: 800px) {
  .dashboard-header-sales {
    flex-direction: column;
    align-items: left;
  }

  .back-button-sales {
    width: 50px;
    height: 50px;
    margin-bottom: 10px;
    margin-right: 0;
  }

  .sales-container h1 {
    font-size: 24px;
  }

  .sales-container th, .sales-container td {
    font-size: 16px;  /* Adjusted for better readability */
  }

  .sales-container th:nth-child(8),
.sales-container td:nth-child(8) {
    width: 300px;
    min-width: 300px;
}


  .sales-dropdown {
    z-index: 1000; /* You can adjust this value based on your needs */
    white-space: nowrap;    /* Prevent text from breaking onto the next line */
    overflow: visible;      /* Display overflow content */
    cursor: 'pointer'
  }

  .sales-container td img {
    height: 30px;
  }

  .sales-container select {
    padding: 12px;
    font-size: 14px;
  }

  .item-details-sales {
    display: flex;
    align-items: left;
    margin-bottom: 20px;
  }
  
  .item-details-sales .item-image-sales {
    width: 100px;
    height: 100px;
    border-radius: 7px;
    object-fit: cover;
    margin-right: 20px;
  }
  
}
