/* General & Root Styles */
.mac-book-pro-14-1, .mac-book-pro-14-1 * {
    box-sizing: border-box;
}

.mac-book-pro-14-1 {
    background: url(../assets/back-image-home.png) center/cover no-repeat;
    width: 100vw;
    height: 100vh;
    position: relative;
    overflow: hidden;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 4rem; /* Add padding at the top to make space for navbar */

}

/* Navbar Section */
.logoo, .contact-button3 {
    position: absolute;
    top: 1rem;
    display: flex;
    align-items: center;
}

.logoo {
    left: 2rem;
    width: 5vw; /* Decreased size for the logo */
}

.contact-button3 {
    right: 2rem;
    padding: 0.3rem 1rem;
    background: linear-gradient(159.55deg, rgba(0, 133, 255, 1) 0%, rgba(84, 242, 214, 1) 100%);
}

/* Body Container */
.body-container {
    background-color: rgba(0, 0, 0, 0.5); /* Black with 0.5 translucency */
    border-radius: 15px; /* Rounded corners */
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

/* Titles and Subtitles */
.title, .subtitle {
    color: #ffffff;
    text-align: left;
    font-family: "Raleway", sans-serif;
}

.title {
    font-weight: 900;
    font-size: 3rem;
    margin-bottom: 0.8rem;
}

.subtitle {
    font-weight: 400;
    font-size: 1.2rem;
    margin-bottom: 2rem;
}

/* Buttons and Input Fields */
.contact-button, .contact-button2, .contact-button3 {
    border-radius: 5rem;
    padding: 0.7rem 2rem;
    display: flex;
    align-items: center;
    box-shadow: 0 0.5rem 4rem rgba(0, 133, 255, 0.23);
}

.contact-button {
    flex-direction: row-reverse;
    background: #fdfdfd;
    margin-bottom: 1rem;
}

.contact-button2 {
    background: linear-gradient(159.55deg, rgba(0, 133, 255, 1) 0%, rgba(84, 242, 214, 1) 100%);
}

/* Text inside buttons and input */
.tu-email, .crear-link, .inicio-sesion {
    color: #ffffff;
    font-family: "Raleway", sans-serif;
    font-weight: 600;
    font-size: 1.2rem;
    border: none;
    background: none;
    outline: none;
}

.tu-email {
    margin-left: 0; /* Removes the left margin from the input */
    color: black;
    padding: 0.5rem;
    border-radius: 5rem;
}

.navbar {
    display: flex;
    justify-content: space-between;
    width: 100%;
    position: absolute;
    top: 1rem;
    right: 1.5rem;
}

.logoo, .contact-button3 {
    height: 2.5rem;
    
}

.logoo img {
    max-width: 220%;
    max-height: 220%;
}


/* Media Queries */
@media only screen and (max-width: 800px) {
    .title {
        font-size: 2rem;
    }

    .subtitle {
        margin-top: -0.5rem;
        font-size: 1.1rem;
    }
    
    .contact-button, .contact-button2, .contact-button3 {
        padding: 0.4rem 1.2rem;
    }

    .get-started, .tu-email, .crear-link, .inicio-sesion {
        font-size: 0.9rem;
    }

    .logoo {
        width: 15vw; /* Smaller logo for small devices */
    }

    .tu-email {
        padding: 0.3rem;
        max-width: 60%; /* Reduce the input size */
    }

    .body-container {
        padding: 1.5rem; /* Adjusted padding for small devices */
    }
}
