.spinner {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 4px solid #f3f3f3; /* was 16px */
    border-top: 4px solid #3498db; /* was 16px */
    border-radius: 50%;
    width: 30px; /* was 120px */
    height: 30px; /* was 120px */
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
    