body {
    font-family: 'Raleway', sans-serif;
    font-weight: 700; /* This is for making the text bold */
  }

.item-page-client {
    max-width: 600px;
    padding: 20px;
    margin: 0 auto;
    margin-top: 2%;
  }

  .back-button-client {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 7px;
    background-color: #f3f3f3;
    cursor: pointer;
    border: none;
    margin-bottom: 10px;
  }
  
  .back-button img {
    width: 50%;
    height: auto;
  }
  
  
  .item-image-container-client {
    margin: 0 auto;
    display: flex;
    overflow-x: auto;
    white-space: nowrap;
    flex-direction: row;
}

.item-image-container-client > div {
    position: relative;
    flex-shrink: 0;
    width: 90vw; 
    height: 90vw; 
    max-width: 350px; /* or whatever maximum you find appropriate */
    max-height: 350px; /* or whatever maximum you find appropriate */
    margin-right: 25px;
}

.item-image-container-client > div:last-child {
  margin-right: 0;
}

.item-image-container-client img {
    object-fit: cover;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
}

.single-image-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.single-image-container img {
  width: 90vw;
  height: 90vw;
  max-width: 350px; /* or whatever maximum you find appropriate */
  max-height: 350px; /* or whatever maximum you find appropriate */
  object-fit: cover;
}

.dots {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #ddd;
  margin: 0 5px;
  cursor: pointer;
}

  
  .item-condition {
    position: absolute;
    top: 10px;
    left: 10px;
    padding: 5px 10px;
    border-radius: 20px;
    font-size: 12px;
    color: #fff;
    z-index: 1; /* Make sure condition tag is above the image */
  }
  
  
  .item-text-container {
    text-align: left;
    margin: 10px 0; /* Adjust space between text and image */
  }
  
  .item-condition.new {
    background-color: #4caf50;
  }
  
  .item-condition.used {
    background-color: #ff9800;
  }
  
  
  .buy-button {
    display: block;
    width: 100%;
    padding: 15px 0;
    margin-top: 30px;
    font-size: 16px;
    text-align: center;
    color: #fff;
    background-color: #000;
    border: none;
    border-radius: 7px;
    cursor: pointer;
    text-decoration: none;
    font-family: 'Raleway';
    font-weight:'700';
  }
  