.store-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* This will always create a 3-column layout */
    grid-gap: 10px;
    padding: 20px;
    max-width: 600px;
    margin: 0 auto;
}


.store-item {
  position: relative;
  width: 100%;
  padding-top: 100%; /* This will make the item square */
  background-color: #F3F3F3;
  overflow: hidden;
}

.store-item-img {
  position: absolute; /* Absolute positioning to fit the image within the square item */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.item-text-container-store {
  text-align: left;
  margin: 10px 0; /* Adjust space between text and image */
}

.store-item-tag {
  position: absolute;
  bottom: 10px;
  right: 10px;
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 12px;
  color: #fff;
}

.item-condition-status-store {
  position: absolute;
  top: 10px;
  left: 10px;
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 12px;
  color: #fff;
  z-index: 1;
}

.item-condition-store {
  position: absolute;
  top: 40px; /* Adjust this value */
  left: 10px;
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 12px;
  color: #fff;
  z-index: 1;
}

.condition-container-store {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 1;
  display: flex;
  flex-direction: column;  /* This will stack the child items vertically */
}

.item-condition-status-store, .item-condition-store {
  white-space: nowrap;  /* Prevents wrapping of text */
}

.item-condition-status-store.sold {
  background-color: #af4c4c;
}

@media (max-width: 800px) {
  .condition-container-store {
    position: absolute;
    top: 50px;
    left: 5px;
    z-index: 1;
    display: flex;
    flex-direction: column;  /* This will stack the child items vertically */
  }
  .item-condition-status-store {
    position: absolute;
    top: 30%;
    left: 1px;
    padding: 3px 5px;
    border-radius: 20px;
    font-size: 7px;
    color: #fff;
    z-index: 1;
  }
  .item-condition-store {
    visibility: hidden;
  }
}