.icon-button {
  background: #D9D9D9;
  border-radius: 50%;
  color: black;
  height: 30px;
  width: 30px;
  margin-left: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

h1 {
  margin-top: 50px;
  margin-bottom: 50px;
}

.icon-button-contiune {
  background: #fff;
  border-radius: 50%;
  color: black;
  height: 30px;
  width: 30px;
  margin-left: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.payment-option-title {
  display: flex;
  justify-content: space-between;
  padding: 1em;
  cursor: pointer;
  align-items: center;
  color: #000; 
}

.payment-button {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1em;
  background: #F3F3F3;
  border-radius: 15px;
  border: none;
  font-family: Raleway;
  font-weight: 700;
  font-size: 18px;
  color: #000; 
  margin-bottom: 2em;
}

.payment-button.black {
  background: #000;
  color: #fff;
}

.payment-option {
  width: 100%;
  background: #F3F3F3;
  border-radius: 15px;
  margin-bottom: 2em;
  padding: 0;
  margin-top: 2em; /* Added margin-top to match the MercadoPago button */
  font-family: Raleway;
  font-weight: 700;
  font-size: 18px;
}

.payment-input {
  flex-grow: 1;
  background: #F9F9F9;
  border: none;
  border-radius: 9px;
  font-family: Raleway;
  font-weight: 700;
  font-size: 12px;
  color: #000; 
  padding-left: 15px;
  margin: 0 1em;
  align-items: center;
  line-height: 3.5; 
}
